<template>
    <el-container>
        <el-main>
            <el-form ref="form" :model="form" label-width="160px" :rules="rules">
                <el-form-item label="论坛标题：">
                    <el-input v-model="form.title" :disabled="form.id"></el-input>
                </el-form-item>
                <el-form-item label="论坛分类：" prop="cate_id">
                    <el-select v-model="form.cate_id" clearable style="width: 700px" placeholder="请选择" filterable
                        @blur="blurRules('cate_id')" :disabled="form.id">
                        <el-option v-if="form.id" label="不选择分类" :value="0"></el-option>
                        <el-option v-for="item in typeList" :key="item.id" :label="item.name" :value="item.id"
                            :disabled="!item.is_show"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="头像：" prop="picture">
                    <div v-if="form.id">
                        <img :src="form.avater" alt="img" style="width:110px;height:110px;">
                    </div>
                    <div v-else>
                        <ReadyUploadSource @getSource="getImg($event, 'avater')" @removeThis="removeImg('avater')"
                            :path="form.avater"></ReadyUploadSource>
                    </div>
                    <span style="color: #999;">不填默认为系统头像</span>
                </el-form-item>
                <el-form-item label="用户昵称：">
                    <el-input v-model="form.nick_name" :disabled="form.id"></el-input>
                    <span style="color: #999; margin-left: 10px">不填默认为系统昵称</span>
                </el-form-item>
                <el-form-item label="是否置顶：">
                    <el-switch v-model="form.is_tops" :active-value="1" :inactive-value="0" :disabled="form.id"></el-switch>
                </el-form-item>
                <el-form-item label="是否显示：">
                    <el-switch v-model="form.is_show" :active-value="1" :inactive-value="0" :disabled="form.id"></el-switch>
                </el-form-item>
                <el-form-item label="论坛样式：">
                    <el-radio-group v-model="form.content_type" @change="changeChooce" :disabled="form.id">
                        <el-radio :label="1">文章</el-radio>
                        <el-radio :label="2">视频</el-radio>
                        <el-radio :label="3">图文</el-radio>
                    </el-radio-group>
                </el-form-item>
                <template v-if="form.content_type == 1">
                    <el-form-item label="封面图片：" prop="content_data">
                        <div v-if="form.id">
                            <img :src="form.content_data" alt="img" style="width:110px;height:110px;">
                        </div>
                        <div v-else>
                            <ReadyUploadSource @getSource="getImg($event, 'content_data')"
                                @removeThis="removeImg('content_data', $event)" :path="form.content_data">
                            </ReadyUploadSource>
                        </div>
                    </el-form-item>
                    <el-form-item label="发布详情：" prop="content">
                        <div>
                            <RichText :height="300" :width="700" :richTxt="form.content" @soninfo="getRichTxt"></RichText>
                        </div>
                    </el-form-item>
                </template>
                <template v-if="form.content_type == 2">
                    <el-form-item label="添加视频：" prop="content_data">
                        <div v-if="form.id">
                            <video :src="form.content_data" style="width:300px;height:200px;"
                                @mouseenter="($event) => { $event.target.play() }"
                                @mouseleave="($event) => { $event.target.pause() }"></video>
                        </div>
                        <div v-else>
                            <ReadyUploadSource :showStyle="{
                                width: '300px',
                                height: '200px'
                            }" @getSource="getImg($event, 'content_data')" @removeThis="removeImg('content_data')"
                                type="video" :path="form.content_data"></ReadyUploadSource>
                        </div>
                        <span style="color: #999;">建议视频尺寸600px*400px，视频大小，限制50M/个以内，格式支持mp4、3gp、m3u8</span>
                    </el-form-item>
                    <el-form-item label="添加文本：" prop="content">
                        <el-input type="textarea" :autosize="{ minRows: 2 }" placeholder="请输入内容" v-model="form.content"
                            :disabled="form.id">
                        </el-input>
                    </el-form-item>
                </template>
                <template v-if="form.content_type == 3">
                    <el-form-item label="添加图片：" prop="content_data">
                        <div v-if="form.id">
                            <img :src="item" alt="img" style="width:100px;height:100px;margin:0 10px 10px 0;"
                                v-for="(item, index) in form.content_data" :key="index">
                        </div>
                        <div v-else>
                            <ReadyUploadSource :isMany="true" :isManyMax="9" @getSource="getImg($event, 'content_datas')"
                                @removeThis="removeImg('content_datas')" :showStyle="{
                                    width: '100px',
                                    height: '100px'
                                }" :manyPath="form.content_data"></ReadyUploadSource>
                        </div>
                        <span style="color: #999;">建议图片尺寸750px*750px，大小不超过1M，最多可以上传9张图片</span>
                    </el-form-item>
                    <el-form-item label="添加文本：" prop="content">
                        <el-input type="textarea" :autosize="{ minRows: 2 }" placeholder="请输入内容" v-model="form.content"
                            :disabled="form.id">
                        </el-input>
                    </el-form-item>
                </template>

                <div v-if="!form.id">
                    <Preservation @preservation="submit('form')">
                    </Preservation>
                </div>
            </el-form>
        </el-main>
    </el-container>
</template>

<script>
import RichText from '@/components/richText'
import ReadyUploadSource from '@/components/readyUploadSource'
import Preservation from '@/components/preservation'

export default {
    components: {
        RichText,
        ReadyUploadSource,
        Preservation
    },
    data () {
        return {
            typeList: [],
            classify_name: '',
            form: {
                title: '',
                cate_id: '',
                avater: '',
                nick_name: "",
                is_show: 1,
                is_tops: "",
                content_type: 1,
                content: '',
                content_data: '',
                cover: ''
            },
            rules: {
                cate_id: [{ required: true, message: '请选择论坛分类', trigger: 'change' }],
                content: [{ required: true, message: '请填写文本', trigger: 'blur' }],
                content_data: [{ required: true, message: '请添加文件', trigger: 'blur' }]
            },
            shopinfo: ''
        }
    },
    watch: {
        form: {
            handler (val) {
                this.$store.state.utils.is_update = 1
            },
            deep: true
        }
    },
    created () {
        this.getType()
        this.getShopinfo()
        let id = this.$route.query.id
        if (id) {
            let utils = this.$store.state.utils
            utils.page = Number(this.$route.query.page)
            utils.rows = Number(this.$route.query.rows)
            utils.is_record = !0
            this.getDetail(id)
        }
    },
    methods: {
        submit (val) {
            this.$refs[val].validate((valid) => {
                if (valid) {
                    let form = this.form
                    if (!form.avater) {
                        form.avater = this.shopinfo.logo
                    }
                    if (!form.nick_name) {
                        form.nick_name = this.shopinfo.name
                    }
                    this.$axios.post(this.$api.samecity.forum.postsAdd, form).then((res) => {
                        if (res.code == 0) {
                            this.$message({
                                message: '添加成功',
                                type: 'success'
                            })
                            this.$router.push('/extension/forum/list')
                        } else {
                            this.$message.error(res.msg)
                        }
                    })
                }
            })
        },
        getType () {
            this.$axios
                .post(this.$api.samecity.forum.classifyList)
                .then((res) => {
                    if (res.code == 0) {
                        this.typeList = res.result.list.list
                    }
                })
        },
        // 添加媒体
        getImg (val, str) {
            if (str == 'content_datas') {
                let arr = val.map(item => item.path)
                this.form['content_data'] = [...this.form['content_data'], ...arr]
            } else {
                if (this, this.form.content_type == 2) {
                    if (val.cover) {
                        this.form['content_data'] = val.path
                        this.form['cover'] = val.cover
                    } else {
                        this.form['content_data'] = ''
                        this.$message.error('素材库里没有该视频封面，请重新上传视频到素材库')
                    }
                } else {
                    this.form[str] = val.path
                }
            }
        },
        // 移除媒体
        removeImg (str, index) {
            if (str == 'content_datas') {
                this.form['content_data'].splice(index, 1)
            } else {
                this.form[str] = ''
            }
        },
        getRichTxt (val) {
            this.form.content = val
        },
        blurRules (prop) {
            this.$refs.form.validateField(prop, (val) => {
                console.log(val);
            })
        },
        getShopinfo () {
            this.$axios.post(this.$api.user.shopInfo).then((res) => {
                if (res.code == 0) {
                    this.shopinfo = res.result.info
                }
            })
        },
        getDetail (id) {
            this.$axios.post(this.$api.samecity.forum.postsDetail, { id }).then((res) => {
                if (res.code == 200) {
                    let info = res.result
                    let form = {
                        title: info.title,
                        cate_id: info.cate_id,
                        avater: info.avater,
                        nick_name: info.nick_name,
                        is_show: info.is_show,
                        is_tops: info.is_tops,
                        content_type: info.content_type,
                        content: info.content,
                        content_data: info.content_data ? JSON.parse(info.content_data) : info.content_data,
                        id: id,
                        cover: info.cover
                    }
                    this.form = form
                }
            })
        },
        changeChooce () {
            this.form.content = ""
            this.form.content_data = ""
            this.form.cover = ''
        }
    }
}
</script>

<style lang="less" scoped>
.el-container {
    height: 100%;
    overflow: hidden;
    overflow-y: auto;
    background: #fff;

    .el-main {
        .el-form {
            padding: 0px 0 60px 0;

            .el-form-item {

                .el-input,
                .el-textarea {
                    width: 700px;
                }

                /deep/ .el-date-editor.el-input {
                    width: 300px;
                }
            }
        }
    }
}
</style>
